<template>
    <base-content>
        <template #button>
            <a-button type="primary" @click="show_add_modal = true"><PlusOutlined />添加</a-button>
        </template>
        <a-table :columns="columns" :data-source="role_list" :pagination="false" rowKey="id" bordered :loading="loading">
            <template #operation="{record}">
                <!-- <a-button type="link" size="small" @click="showModal(false, record.id)">查看</a-button> -->
                <a-button type="link" size="small" @click="selectUser(record.id)">用户变更</a-button>
                <a-button type="link" size="small" @click="editRole(record.id)">修改</a-button>
                <a-button type="link" size="small" @click="showModal(false, record.id)">删除</a-button>
            </template>
            <template #expandedRowRender="{ record }">
                <a-tag :color="{ 未知: 'purple', 男: 'cyan', 女: 'pink' }[user.gender]" style="padding:0;margin:6px" v-for="user in record.user_list" :key="user.id">
                    <div style="display:flex;flex-direction:column;">
                        <a-avatar shape="square" :size="64" :src="user.avatar">头像</a-avatar>
                        <span style="text-align:center">{{ user.name }}</span>
                    </div>
                </a-tag>
                <span v-if="record.user_list.length == 0">这里空空的...</span>
            </template>
        </a-table>
        <a-modal v-model:visible="show_add_modal" title="新增角色" :footer="null">
            <a-form ref="add_form" :model="add_form" :rules="rules" :label-col="{ span: 4, offset: 1 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="角色名称" name="name">
                    <a-input v-model:value="add_form.name" />
                </a-form-item>
                <a-form-item label="备注" name="remark">
                    <a-textarea v-model:value="add_form.remark" :auto-size="{ minRows: 2, maxRows: 5 }" />
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 14, offset: 5 }">
                    <a-button type="primary" @click="submitAdd" style="margin-right:20px">提交</a-button>
                    <a-button @click="show_add_modal = false">关闭</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model:visible="show_edit_modal" title="修改角色" :footer="null">
            <a-form ref="edit_form" :model="edit_form" :rules="rules" :label-col="{ span: 4, offset: 1 }" :wrapper-col="{ span: 15 }">
                <a-form-item label="角色名称" name="name">
                    <a-input v-model:value="edit_form.name" />
                </a-form-item>
                <a-form-item label="备注" name="remark">
                    <a-textarea v-model:value="edit_form.remark" :auto-size="{ minRows: 2, maxRows: 5 }" />
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 14, offset: 5 }">
                    <a-button type="primary" @click="saveEdit" style="margin-right:20px">保存</a-button>
                    <a-button @click="show_edit_modal = false">关闭</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
        <SelectUserModal ref="SelectUserModal" @finish="finishSelectUser" />
    </base-content>
</template>
<script>
import SelectUserModal from '../components/modal/SelectUser';
import { PlusOutlined } from '@ant-design/icons-vue';
export default {
    components: { PlusOutlined, SelectUserModal },
    data() {
        return {
            loading: false,
            show_add_modal: false,
            add_form: {
                name: '',
                remark: '',
            },
            show_edit_modal: false,
            edit_form: {
                role_id: 0,
                name: '',
                remark: '',
            },
            rules: {
                name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
            },
            // 正在选择用户的角色id
            select_user_role_id: 0,
            role_list: [],
            columns: [
                { title: 'ID', dataIndex: 'id', width: 50, align: 'center' },
                { title: '角色名称', dataIndex: 'name', width: 120 },
                { title: '描述', dataIndex: 'remark' },
                { title: '操作', dataIndex: '', width: 200, align: 'center', slots: { customRender: 'operation' } },
            ],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this.$api('getRoleList', {}, true).then(async (res) => {
                let data = [];
                for (let i = 0; i < res.role_list.length; i++) {
                    let item = res.role_list[i];
                    let user_list = [];
                    for (let j = 0; j < item.user_list.length; j++) {
                        let user_info = await this.$api('getUserInfo', { user_id: item.user_list[j] });
                        user_list.push(user_info);
                    }
                    item.user_list = user_list;
                    data.push(item);
                }
                this.role_list = data;
                this.loading = false;
            });
        },
        submitAdd() {
            this.$refs.add_form.validate().then(() => {
                this.$api('addRole', this.add_form).then((res) => {
                    if (res.result == '操作成功') {
                        this.$message.success('添加成功');
                        this.show_add_modal = false;
                        this.add_form = { name: '', remark: '' };
                        this.getData();
                    }
                });
            });
        },
        selectUser(role_id) {
            this.role_list.forEach((role) => {
                if (role.id == role_id) {
                    let user_list = [];
                    role.user_list.forEach((user) => {
                        user_list.push(user.user_id);
                    });
                    this.$refs.SelectUserModal.start(user_list);
                    this.select_user_role_id = role_id;
                }
            });
        },
        finishSelectUser(user_id_list) {
            this.$api('changeRoleUser', { role_id: this.select_user_role_id, user_id_list }).then((res) => {
                this.getData();
            });
        },
        editRole(role_id) {
            console.log(role_id);
            console.log(this.role_list);
            this.role_list.forEach((role) => {
                if (role.id == role_id) {
                    this.show_edit_modal = true;
                    this.edit_form = {
                        role_id: role.id,
                        name: role.name,
                        remark: role.remark,
                    };
                }
            });
        },
        saveEdit() {
            this.$refs.edit_form.validate().then(() => {
                this.$api('editRole', this.edit_form).then((res) => {
                    if (res.result == '操作成功') {
                        this.$message.success('修改成功');
                        this.show_edit_modal = false;
                        this.edit_form = { role_id: 0, name: '', remark: '' };
                        this.getData();
                    }
                });
            });
        },
    },
};
</script>
